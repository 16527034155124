import { Box, Button, Paper, Select, Text } from "@mantine/core";
import React, { Suspense, useEffect, useState, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../connector/ModuleHook";
import { RootState } from "../connector/ModuleStore";
import { GetCorporateDashboardService } from "../interactor/CorporateDashboardInteractor";
import { GetCorporateDashboardRequest } from "../data/request/GetCorporateDashboardRequest";
import CustomLoadingOverlay from "../../utils/CustomLoadingOverlay";
import MainWrapper from "../../../app/common/presentation/view/component/mainWrapper";
import { IconTractor, IconUser, IconMap } from "@tabler/icons-react";
import { CityService } from "../../myfields/interactor/CityInteractor";
import { DistrictService } from "../../myfields/interactor/DistrictInteractor";
import { DistrictRequest } from "../../myfields/data/request/DistrictRequest";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, TooltipItem, Chart } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const CorporateDashboard = () => {
  const [fetching, setFetching] = useState(true);
  const dispatch = useAppDispatch();
  const [cityIdQuery, setCityIdQuery] = useState<string>("");
  const [districtIdQuery, setDistrictIdQuery] = useState<string>("");
  const [plantIdQuery, setPlantIdQuery] = useState<string>("");

  const corporateDashboardService = useAppSelector(
    (state: RootState) => state.GetCorporateDashboardState?.value?.data
  );

  const cityService = useAppSelector((state: RootState) => state.GetCitiesState.value);
  const cityData = cityService?.data?.cities;

  const districtService = useAppSelector((state: RootState) => state.GetDistrictState.value);
  const districtData = districtService?.data?.districts;

  const colorMap = useRef<{ [key: string]: string }>({});

  const randomMatColor = () => {
    const hue = Math.floor(Math.random() * 360);
    const saturation = Math.floor(Math.random() * 30) + 20;
    const lightness = Math.floor(Math.random() * 30) + 20;
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  };

  const getColorFromStorage = (key: string) => {
    const storedColors = JSON.parse(localStorage.getItem('colorMap') || '{}');
    return storedColors[key];
  };

  const saveColorToStorage = (key: string, color: string) => {
    const storedColors = JSON.parse(localStorage.getItem('colorMap') || '{}');
    storedColors[key] = color;
    localStorage.setItem('colorMap', JSON.stringify(storedColors));
  };

  const assignColor = (key: string) => {
    let color = getColorFromStorage(key);
    if (!color) {
      color = randomMatColor();
      saveColorToStorage(key, color);
    }
    return color;
  };

  useEffect(() => {
    dispatch(CityService());
  }, []);

  useEffect(() => {
    if (cityIdQuery) {
      dispatch(DistrictService(new DistrictRequest(cityIdQuery, "")));
    }
  }, [cityIdQuery]);

  useEffect(() => {
    GetData();
  }, [cityIdQuery, districtIdQuery, plantIdQuery]);

  const GetData = () => {
    dispatch(GetCorporateDashboardService({
      cityId: cityIdQuery ?? '',
      districtId: districtIdQuery ?? '',
      plantId: plantIdQuery ?? '',
    } as GetCorporateDashboardRequest));
  };

  const firmPlants = corporateDashboardService?.field
    .flatMap(field => field.seasons ?? [])
    .filter((season, index, self) =>
      season?.plantId && self.findIndex(s => s?.plantId === season.plantId) === index
    )
    .map(season => ({
      value: season.plantId,
      label: season.plantName || "Sezon Kapalı Ürün Yok"
    }));

  const clearFilters = () => {
    setCityIdQuery("");
    setDistrictIdQuery("");
    setPlantIdQuery("");
    GetData();
  };

  // PİE CHART KISIMLARI

  const pieData1 = {
    labels: corporateDashboardService?.field
      .map(field => field.address?.city?.name)
      .filter((value, index, self) => self.indexOf(value) === index) || [],
    datasets: [
      {
        data: corporateDashboardService?.field
          .reduce<{ city: string | undefined; area: number }[]>((acc, field) => {
            const cityName = field.address?.city?.name;
            const area = field.address?.area;
            if (cityName && area !== undefined) {
              const cityIndex = acc.findIndex(item => item.city === cityName);
              if (cityIndex >= 0) {
                acc[cityIndex].area += area;
              } else {
                acc.push({ city: cityName, area });
              }
            }
            return acc;
          }, [])
          .map(item => item.area * 0.001) || [],
        backgroundColor: (corporateDashboardService?.field
          .map(field => field.address?.city?.name)
          .filter((value, index, self) => self.indexOf(value) === index) || [])
          .map(cityName => assignColor(cityName || "")),
      },
    ],
  };

  const pieData2 = {
    labels: corporateDashboardService?.field
      .flatMap(field =>
        field.seasons?.length
          ? field.seasons
            .filter(season => season.seasonClosed === false)
            .map(season => season.plantName || "-")
          : ["Sezon Kapalı"]
      )
      .filter((value, index, self) => value && self.indexOf(value) === index) || [],
    datasets: [
      {
        data: corporateDashboardService?.field
          .flatMap(field =>
            field.seasons?.length
              ? field.seasons
                .filter(season => season.seasonClosed === false)
                .map(season => ({
                  plantName: season.plantName || "Sezon Kapalı",
                  area: field.address?.area || 0,
                }))
              : [{ plantName: "Sezon Kapalı", area: field.address?.area || 0 }]
          )
          .reduce<{ plantName: string; area: number }[]>((acc, item) => {
            const plantIndex = acc.findIndex(el => el.plantName === item.plantName);
            if (plantIndex >= 0) {
              acc[plantIndex].area += item.area;
            } else if (item.plantName) {
              acc.push({ plantName: item.plantName, area: item.area });
            }
            return acc;
          }, [])
          .map(item => item.area * 0.001) || [],
        backgroundColor: (corporateDashboardService?.field
          .flatMap(field =>
            field.seasons?.length
              ? field.seasons
                .filter(season => season.seasonClosed === false)
                .map(season => season.plantName || "Sezon Kapalı")
              : ["Sezon Kapalı"]
          )
          .filter((value, index, self) => value && self.indexOf(value) === index) || [])
          .map(plantName => assignColor(plantName || "")),
      },
    ],
  };







  const options1 = {
    layout: {
      padding: {
        left: 0,
        right: -30,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const options2 = {
    layout: {
      padding: {
        left: 0,
        right: -30,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const renderLabels = (data: any) => (
    data.labels.map((label: string, index: number) => (
      <Box key={index} sx={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
        <Box
          sx={{
            width: "16px",
            height: "16px",
            backgroundColor: data.datasets[0].backgroundColor[index],
            marginRight: "8px",
          }}
        />
        <Text size="sm">{label}: {data.datasets[0].data[index].toFixed(2)} dekar</Text>
      </Box>
    ))
  );

  return (
    <Suspense fallback={<CustomLoadingOverlay />}>
      <MainWrapper breadCrumbs={[{ title: "Dashboard", href: "#" }]} title="Dashboard">
        <Paper shadow="xs" p="xl" pt="0" className="profile-paper">
          <Suspense fallback={<CustomLoadingOverlay />}>
            <Paper p="xl" className="profile-paper" pos={"relative"}>
              <Box sx={{ display: "flex", gap: "20px", marginBottom: "20px", alignItems: "center" }}>

                <Select
                  placeholder="İl Seçiniz"
                  searchable
                  nothingFound="Veri Bulunamadı"
                  data={cityData?.filter((city) =>
                    corporateDashboardService?.field?.some(field => field.address?.city?.id === city.id)
                  ).map((city: any) => ({
                    value: city.id,
                    label: city.name
                  })) || []}
                  value={cityIdQuery}
                  onChange={(value) => {
                    setCityIdQuery(value ?? '');
                    setDistrictIdQuery('');
                  }}
                  clearable
                />

                <Select
                  placeholder="İlçe Seçiniz"
                  searchable
                  nothingFound="Veri Bulunamadı"
                  data={districtData?.filter((district) =>
                    corporateDashboardService?.field?.some(field => field.address?.district?.id === district.id)
                  ).map((district: any) => ({
                    value: district.id,
                    label: district.name
                  })) || []}
                  value={districtIdQuery}
                  onChange={(value) => setDistrictIdQuery(value ?? '')}
                  clearable
                  disabled={!cityIdQuery}
                />

                <Select
                  placeholder="Ürün Seçiniz"
                  searchable
                  nothingFound="Veri Bulunamadı"
                  data={firmPlants || []}
                  value={plantIdQuery}
                  onChange={(value) => setPlantIdQuery(value ?? '')}
                  clearable
                />
              </Box>

              <Button onClick={clearFilters} variant="outline" color="red" sx={{ marginTop: "10px" }}>
                Filtreleri Temizle
              </Button>

              <Box sx={{ display: "flex", gap: "10px", marginTop: "20px" }}>
                <Box sx={{ backgroundColor: "#28a745", padding: "8px", borderRadius: "8px", color: "#fff", width: 150 }}>
                  <div>
                    <Text size="lg" weight={200}>{corporateDashboardService?.fieldCount || 0}</Text>
                    <Text size="sm">Tarla Sayısı</Text>
                  </div>
                  <IconTractor size={32} stroke={1.5} />
                </Box>
                <Box sx={{ backgroundColor: "#007bff", padding: "8px", borderRadius: "8px", color: "#fff", width: 150, marginLeft: "100px" }}>
                  <div>
                    <Text size="lg" weight={200}>{corporateDashboardService?.farmerCount || 0}</Text>
                    <Text size="sm">Üretici Sayısı</Text>
                  </div>
                  <IconUser size={32} stroke={1.5} />
                </Box>
                <Box sx={{ backgroundColor: "#dc3545", padding: "8px", borderRadius: "8px", color: "#fff", width: 150, marginLeft: "100px" }}>
                  <div>
                    <Text size="lg" weight={200}>
                      {corporateDashboardService?.totalFieldArea
                        ? new Intl.NumberFormat("tr-TR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                          .format(corporateDashboardService.totalFieldArea * 0.001)
                        : "0,00"}
                    </Text>
                    <Text size="sm">Tarla Alanı (Dekar)</Text>
                  </div>
                  <IconMap size={32} stroke={1.5} />
                </Box>
              </Box>

              <Box sx={{ display: "flex", gap: "20px", alignItems: "start", width: "100%", marginTop: "30px" }}>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "50%" }}>
                  <Box sx={{ display: "flex", gap: "20px", alignItems: "start", width: "100%" }}>
                    <Paper shadow="sm" p="md" sx={{ width: "50%", maxHeight: "250px", overflowY: "auto" }}>
                      {renderLabels(pieData1)}
                    </Paper>
                    <Box sx={{ width: "50%", height: "400px" }}>
                      <Pie data={pieData1} options={options1} />
                    </Box>
                  </Box>
                </Box>

                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "50%" }}>
                  <Box sx={{ display: "flex", gap: "20px", alignItems: "start", width: "100%", justifyContent: "flex-end" }}>
                    <Box sx={{ width: "50%", height: "400px" }}>
                      <Pie data={pieData2} options={options2} />
                    </Box>
                    <Paper shadow="sm" p="md" sx={{ width: "50%", maxHeight: "250px", overflowY: "auto" }}>
                      {renderLabels(pieData2)}
                    </Paper>
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Suspense>
        </Paper>
      </MainWrapper>
    </Suspense>
  );
};

export default CorporateDashboard;
