import {
  PaperProps,
  Container,
  Grid,
  MediaQuery,
  Skeleton,
  Group,
  TextInput,
  rem,
  PinInput,
  Button,
  Text,
  Flex,
  LoadingOverlay,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useToggle } from "@mantine/hooks";
import { set, upperFirst } from "lodash";
import { Check } from "phosphor-react";
import { useState, useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router";
import CustomLoadingOverlay from "../../../utils/CustomLoadingOverlay";
import { useAppDispatch, useAppSelector } from "../../connector/ModuleHook";
import { OTPRequest, LoginRequest } from "../../data/request/LoginRequests";
import { LoginService } from "../../interactor/LoginInteractor";
import { OTPService } from "../../interactor/OTPInteractor";
import ErrorModal from "../../../utils/ErrorModal";
import { useSelector } from "react-redux";
import { RootState } from "../../connector/ModuleStore";
import { LoginResponse, OTPResponse } from "../../data/response/LoginResponses";
import { toast } from "react-toastify";
import { fetchProfileInfo } from "../../../profile/interactor/ProfileInteractor";

export default function LoginV2(props: PaperProps) {
  const [type, toggle] = useToggle(["Devam Et", "Giriş Yap"]);
  const [otpSuccess, setOtpSuccess] = useState(false);
  const [otp, setOtp] = useState<string>();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const loginState = useAppSelector((state) => state.loginState);
  const otpState = useAppSelector((state) => state.otpState);
  const [alias, setAlias] = useState("");

  const [loading, setLoading] = useState(true);

  const form = useForm({
    initialValues: {
      phoneNumber: "",
      name: "",
      terms: true,
      alias: "",
    },
    validate: {
      phoneNumber: (val) => {
        if (val === null) {
          return "Telefon numarası boş olamaz";
        }

        const numericValue = val.replace(/\D/g, "");

        if (/^\d{10}$/.test(numericValue)) {
          return null; // Valid
        } else {
          return "Geçersiz telefon numarası";
        }
      },
      alias: (value) => (value ? null : "Şirket kodu boş olamaz"),
    },
  });

  useEffect(() => {
    const handleLoad = () => {
      setLoading(false);
    };

    if (document.readyState === "complete") {
      setLoading(false);
    } else {
      window.addEventListener("load", handleLoad);
    }

    return () => {
      window.removeEventListener("load", handleLoad);
    };
  }, []);

  useEffect(() => {
    const activeLink = document.getElementById("root");

    if (activeLink) {
      activeLink.style.overflow = "hidden";
    }

    return () => {
      if (activeLink) {
        activeLink.style.overflow = "";
      }
    };
  }, []);

  useEffect(() => {
    if (loginState?.value?.success === true) {
      toggle();
    }
  }, [loginState]);

  useEffect(() => {
    if (otpState?.value?.messageCode === "IDT201" || otpState?.value?.messageCode === "IDT202") {
      if (otpState?.value?.success === false) {
        setIsErrorModalOpen(true);
      } else if (otpState?.value?.success === true) {
        navigate(process.env.PUBLIC_URL + "/agreement");
      }
    } else if (otpState?.value?.success === true) {
      dispatch(fetchProfileInfo());
      navigate(process.env.PUBLIC_URL + "/myFields");
    }
  }, [otpState]);

  const loginService = useSelector((state: RootState) => state.loginState);
  const loginData = loginService;

  const otpService = useSelector((state: RootState) => state.otpState);
  const otpData = otpService;

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const [fetching, setFetching] = useState(false);

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setFormSubmitted(true);

    if (form.isValid()) {
      setFetching(true);
      const cleanedPhoneNumber = form.values.phoneNumber.replace(/\D/g, "");
      if (type === "Giriş Yap") {
        const response = dispatch(
          OTPService(new OTPRequest(otp, "+90", cleanedPhoneNumber, alias))
        ).then((response) => {
          if (response !== undefined) {
            let payload = response.payload as OTPResponse | undefined;
            if (payload?.success) {
              setFetching(false);
              localStorage.setItem("weatherFav", "accuweather");
 
              toast.success(payload?.userMessage);
            } else {
              setFetching(false);
              toast.error(
                payload?.userMessage || "Şifre doğrulaması başarısız"
              );
            }
          }
        });
      } else {
        dispatch(
          LoginService(new LoginRequest("+90", cleanedPhoneNumber, alias))
        ).then((response) => {
          if (response != undefined) {
            let payload = response.payload as LoginResponse | undefined;
            if (payload?.success) {
              setFetching(false);
              toast.success(payload?.userMessage);
            } else {
              setFetching(false);
              toast.error("Hatalı şirket kodu veya telefon numarası");
            }
          }
        });
      }
    } else {
    }
  };

  const formatPhoneNumber = (value: string) => {
    const inputVal = value.replace(/\D/g, "");

    if (inputVal.length === 0 || value === "() ") {
      return "";
    } else if (inputVal.length <= 10) {
      return `(5${inputVal.slice(1, 3)}) ${inputVal.slice(
        3,
        6
      )} ${inputVal.slice(6, 10)}`;
    } else {
      return `(5${inputVal.slice(1, 3)}) ${inputVal.slice(
        3,
        6
      )} ${inputVal.slice(6, 10)}`;
    }
  };

  return (
    <Container fluid pl={0} pr={0}>
      {loading && <CustomLoadingOverlay></CustomLoadingOverlay>}
      <Grid className="login-background-box" align="center" justify="center">
        <MediaQuery query="(max-width: 62em)" styles={{ paddingTop: "30vh" }}>
          <Grid.Col sm={12} md={6}>
            <Container fluid>
              <Grid>
                <Grid.Col span={8} offset={2}>
                  <Flex justify={"center"} align={"center"}>
                    <Grid pos={"relative"}>
                      <LoadingOverlay
                        visible={fetching}
                        overlayBlur={2}
                        loaderProps={{
                          size: "md",
                          color: "#0097c4",
                          variant: "oval",
                        }}
                        pos={"absolute"}
                      />
                      <Grid.Col span={12}>
                        <LazyLoadImage
                          src="/assets/images/login_logo.png"
                          width={170}
                          alt="Background Alt"
                          placeholder={<Skeleton />}
                          effect="blur"
                          style={{ objectFit: "contain" }}
                        />
                      </Grid.Col>
                      <Grid.Col span={12} mt="sm">
                        <Group>
                          {type === "Devam Et" && (
                            <>
                              <Check
                                className="login-stepper login-stepper-selected"
                                size={32}
                                weight="bold"
                              />
                              <Check
                                className="login-stepper login-stepper-unselected"
                                size={32}
                              />
                            </>
                          )}
                          {type === "Giriş Yap" && (
                            <>
                              <Check
                                className="login-stepper login-stepper-unselected"
                                size={32}
                              />
                              <Check
                                className="login-stepper login-stepper-selected"
                                size={32}
                                weight="bold"
                              />
                            </>
                          )}
                        </Group>
                      </Grid.Col>
                      <Grid.Col span={12}>
                        <form
                          onSubmit={form.onSubmit((values) =>
                            console.log(values)
                          )}
                        >
                          {" "}
                          <Grid>
                            <Grid.Col span={12}>
                              {type === "register" && (
                                <TextInput
                                  label="Name"
                                  placeholder="Your name"
                                  value={form.values.name}
                                  onChange={(event) =>
                                    form.setFieldValue(
                                      "name",
                                      event.currentTarget.value
                                    )
                                  }
                                  radius="md"
                                />
                              )}
                              {type === "Devam Et" && (
                                <Grid>
                                  <Grid.Col span={12}>
                                    <TextInput
                                      radius={"md"}
                                      className="login-input"
                                      value={form.values.alias}
                                      label="Şirket Kodu"
                                      placeholder="Şirket Kodu"
                                      onChange={(event) => {
                                        setAlias(event.currentTarget.value);
                                        form.setFieldValue(
                                          "alias",
                                          event.currentTarget.value
                                        );
                                      }}
                                      error={form.errors.alias}
                                    ></TextInput>
                                  </Grid.Col>
                                  <Grid.Col span={12}>
                                    <TextInput
                                      className="login-input"
                                      label="Telefon numarası"
                                      value={form.values.phoneNumber}
                                      onKeyDown={(event) => {
                                        // Check if the pressed key is delete key
                                        if (event.key === "Backspace") {
                                          event.preventDefault(); // Prevent the default behavior of delete key

                                          // Check if the entire text is selected
                                          const input =
                                            event.target as HTMLInputElement;
                                          if (
                                            input.selectionStart === 0 &&
                                            input.selectionEnd ===
                                              form.values.phoneNumber.length
                                          ) {
                                            // If the entire text is selected, delete all characters
                                            form.setFieldValue(
                                              "phoneNumber",
                                              ""
                                            );
                                          } else {
                                            // If not, delete the last character
                                            const newValue =
                                              form.values.phoneNumber.slice(
                                                0,
                                                -1
                                              );
                                            form.setFieldValue(
                                              "phoneNumber",
                                              newValue
                                            );
                                          }
                                        }
                                      }}
                                      onChange={(event) => {
                                        let inputVal =
                                          event.currentTarget.value.replace(
                                            /\D/g,
                                            ""
                                          );

                                        // Eğer telefon numarası boş ise ve kullanıcı yeni bir karakter girdiyse ve bu karakter '5' değilse, ilk karakteri '5' yap
                                        if (
                                          !inputVal &&
                                          event.currentTarget.value.length ===
                                            1 &&
                                          event.currentTarget.value !== "5"
                                        ) {
                                          inputVal = "5";
                                        }

                                        const formattedVal =
                                          formatPhoneNumber(inputVal);

                                        if (inputVal.length <= 10) {
                                          form.setFieldValue(
                                            "phoneNumber",
                                            formattedVal
                                          );
                                        }
                                      }}
                                      placeholder="(5xx) xxx xxxx"
                                      radius="xs"
                                      mt="sm"
                                      sx={{
                                        "& .mantine-TextInput-label": {
                                          color: "white",
                                        },
                                      }}
                                      style={{
                                        width: "100%",
                                        color: "#000",
                                        textAlign: "left",
                                      }}
                                      error={form.errors.phoneNumber}
                                    />
                                  </Grid.Col>
                                </Grid>
                              )}
                            </Grid.Col>

                            <Grid.Col span={12}>
                              {type === "Giriş Yap" && (
                                <Text weight={500} pb={rem(10)}>
                                  Lütfen Cep telefonunuza gelen 6 haneli kodu
                                  girin
                                </Text>
                              )}

                              {type === "Giriş Yap" && (
                                <PinInput
                                  inputType="tel"
                                  length={6}
                                  inputMode="numeric"
                                  className="otp-pin-input"
                                  onChange={(value: string) => {
                                    setOtp(value);
                                  }}
                                />
                              )}
                            </Grid.Col>
                            <Grid.Col md={5} xs={12} offsetMd={7}>
                              <Button
                                fullWidth
                                className="login-button"
                                type="submit"
                                color="#1E3A8A"
                                onClick={() => {
                                  handleSubmit({ preventDefault: () => {} });
                                }}
                              >
                                {upperFirst(type)}
                              </Button>
                              <Grid.Col span={12}>
                                {type === "Devam Et" && (
                                <Text
                                component="a"
                                href="https://imecemobil.com.tr/hizmetler/imecemobil-propanel"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    color: "#0097c4",
                                    textDecoration: "underline",
                                    display: "block",
                                    textAlign: "center",
                                    marginTop: rem(10),
                                    fontFamily: "Poppins-Regular",
                                }}
                                >
                                Propanel Nedir?
                                </Text>
                                )}
                            </Grid.Col>
                            </Grid.Col>
                          </Grid>
                        </form>
                      </Grid.Col>
                    </Grid>
                  </Flex>
                </Grid.Col>
                <Grid.Col span={2}></Grid.Col>
              </Grid>
            </Container>
          </Grid.Col>
        </MediaQuery>
        <MediaQuery query="(max-width: 62em)" styles={{ display: "none" }}>
          <Grid.Col sm={0} md={6}>
            <LazyLoadImage
              src="/assets/images/login_background.png"
              rel="preload"
              alt="Background Alt"
              placeholder={<Skeleton />}
              className="login-right-image"
              effect="blur"
            />
          </Grid.Col>
        </MediaQuery>
      </Grid>
      <ErrorModal
        isOpen={isErrorModalOpen}
        onClose={() => setIsErrorModalOpen(false)}
        message={otpState.value?.userMessage}
      />
      ;
    </Container>
  );
}
